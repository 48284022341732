import React from "react"
import "./About.scss"
import { Container } from "../layout/Container"

export const About = props => {
  return (
    <section className="vfy-about vfy-section">
      <Container>
        <h2>
          We’re small.
          <br />
          We’re independent. <br />
          We’re majority female owned.
        </h2>
        <p>
          Our small and mighty Vivify team launched Bravazzi and Itz Spritz in
          spring of 2017, and for the last three years, we’ve been on planes,
          trains, and automobiles bringing our brands to fun-loving people
          across the US! We’ve received amazing support along our
          entrepreneurial journey, and have gone from just a handful of markets
          in 2017 to more markets than we can count, across many states
          throughout the country. We’ve got the experience, drive and know-how
          to know what people like you crave and how to create it for you.
        </p>
      </Container>
      <Container className="extra">
        <div className="polaroid" style={{ textAlign: "center" }}>
          <img
            src={"/Anne-Sarah-Bob.jpg"}
            alt="Anne Ellis, Sarah Ross, and Bob Walkenhorst"
          />

          <p>
            <strong>SARAH</strong> was a marketing executive at a big beer
            company; she dreamed of creating something new.
          </p>
          <p>
            <strong>ANNE</strong> was a marketer and met Sarah at aforementioned
            beer company; she dreamed of being an entrepreneur.{" "}
          </p>
          <p>
            <strong>BOB</strong> was a distributor in the beer business; he
            dreamed of creating a brand.
          </p>

          <p>
            We got together and knew it was time to create something of our own.
            Now it’s time for you to try our brands. We know you’ll love ‘em.
          </p>
        </div>
      </Container>
    </section>
  )
}
