import React, { useRef, useEffect  } from 'react';
import "./Hero.scss"
import Logo from "../svg/vivify-logo.svg"
import Ff from "../svg/female-founded.svg"
import { Container } from "../layout/Container"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const Hero = props => {
  const heroRef = useRef(null);

  useEffect(() => {
    console.log(heroRef.current.parentNode);
    gsap.to(heroRef.current, {
      y: "53.5vh",
      ease:"none", 
      scrollTrigger: {
        trigger: heroRef.current.parentNode,
        start: 'top top',
        scrub: true,
      }
    });

    
  });
  return (
  
          <section className="vfy-hero">
            <div className="stripes stripes--right"></div>
            <div className="stripes stripes--left"></div>
           
              <Container ref={heroRef}>
                <Logo className="vfy-hero__logo" />
                <Ff className="vfy-hero__ff" />
              </Container>
           
          </section>
  )
}
