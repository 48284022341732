import React from "react"
import "./SubNav.scss"
import { Container } from "../layout/Container"

export const SubNav = props => {
  return (
    <nav className="vfy-subNav">
      <Container>
        <button onClick={() => props.scrolly('about')}>Our Story</button>
        <button  onClick={() => props.scrolly('brands')}>Our Brands</button>
        <button  onClick={() => props.scrolly('contact')}>Contact Us</button>
      </Container>
    </nav>
  )
}
