import React from "react"
import './Footer.scss'
import Logo from '../svg/vivify-logo.svg'
import {Container} from '../layout/Container'

export const Footer = (props) => {
    return (
        <footer className="vfy-footer">
            <Container>
            <span className="legal">Live joyfully, drink responsibly. ©{new Date().getFullYear()} Vivify Beverages LLC.</span>
            <Logo className="vfy-footer__logo"/>
            </Container>
        </footer>
    )
}