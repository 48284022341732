import React from "react"
import "./CardBrand.scss"
import FbLogo from "../svg/Facebook-logo.svg"
import IgLogo from "../svg/Instagram-logo.svg"

export const CardBrand = props => {
  const LogoImage = props.logo
  return (
    <article onClick={() => window.open(props.href, "_self")} className={`vfy-card--brand vfy-card--brand--${props.name}`}>
      <div className="vfy-card--brand__image">
        <LogoImage />
      </div>
      <div className="everythingElse">
      <hr />
        <a className="vfy-card--brand__webLink" href={props.href}>{props.cta}</a>
        <div className="vfy-card--brand__social">
          <a className="socialLink" href={props.fbLink}>
            <FbLogo
              src={props.fbLogo}
              alt={props.fbAlt}
              className="socialIcon socialIcon--fb"
            />
          </a>
          <a className="socialLink" href={props.igLink}>
            <IgLogo alt={props.igAlt} className="socialIcon socialIcon--ig" />
          </a>
        </div>
      </div>
    </article>
  )
}
