import React, { useRef } from "react"
import { Hero } from "../components/Hero"
import { SubNav } from "../components/SubNav"
import { About } from "../components/About"
import { Brands } from "../components/Brands"
import { Contact } from "../components/Contact"
import { Footer } from "../components/Footer"
import { Layout } from "../layout/Layout"
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
export default function Home() {
  const aboutRef = useRef(null);
  const brandsRef = useRef(null);
  const contactRef = useRef(null);
  function scrolly(elm){
    // alert(elm);
    const scrollTable={
      "about":aboutRef,
      "brands":brandsRef,
      "contact":contactRef
    }
    gsap.to(window, {duration: 1, ease:"power3.inOut", scrollTo:scrollTable[elm].current});
  }
    return (
    <Layout>
      <div className="pager">
        <Hero />
        <SubNav scrolly={scrolly} />
      </div>
      <div ref={aboutRef} />
      <About />
      <div ref={brandsRef} />
     <Brands />
     <div ref={contactRef} />
     <Contact />
      <Footer />
    </Layout>
  )
}
