import React, { useEffect } from "react"
import "./Brands.scss"
import { Container } from "../layout/Container"
import { CardBrand } from "../components/CardBrand"
import bvLogo from "../svg/Bravazzi-ribbon-logo.svg"
import isLogo from "../svg/Itz-Spritz-logo.svg"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export const Brands = props => {
  useEffect(() => {
    const x = document.documentElement.clientWidth
    const diag = document.getElementById("diags")
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: diag,
        start: `top-=${x} bottom`,
        end: `top+=${x} top`,
        scrub: true,
        // markers: true,
      },
    })

    // add animations and labels to the timeline
    tl.addLabel("start")
      .set(diag, { skewY: "-30deg" })
      // .to(diag, { skewY: "12deg", ease:"power2.inOut" })
      .to(diag, { skewY: "-12deg", ease: "power1.inOut" })
      .to(diag, { skewY: "10deg", ease: "power1.inOut" })
      .to(diag, { skewY: "-12deg", ease: "power1.inOut" })
  })

  return (
    <section className="vfy-brands vfy-section">
      <div id="diags" className="diags"></div>
      <Container>
        <h2>We Proudly Craft Two Delicious Beverages.</h2>

        <img
          src={"/Vivify-Group-Shot.png"}
          alt="Anne Ellis, Sarah Ross, and Bob Walkenhorst"
          className="vfy-brands__image"
        />
        <p>
          Bravazzi is our love letter to Italy. Like the Italians, we started
          with the best ingredients. We brewed pure sugarcane so Bravazzi is
          100% gluten free. We used real juice and natural ingredients to get
          four unique varieties that burst with sun-ripened, true-to-fruit
          citrus flavor. Try one today.
        </p>
        <p>
          Or, close your eyes with an Itz Spritz and be whisked away to the
          south of France... Itz full of flavor. Itz lightly sparkling. Itz made
          using just five simple, natural ingredients. Itz light, bright,
          sparkling joy in a can.
        </p>
        <div className="vfy-brands__cards">
          <Container>
            <div className="offset">
              <div className="vfy-brands__card">
                <CardBrand
                  name="bravazzi"
                  logo={bvLogo}
                  alt="Bravazzi logo"
                  href="https://www.bravazzi.com"
                  cta="Visit Bravazzi.com"
                  fbLink="https://www.facebook.com/drinkbravazzi/"
                  fbAlt="Follow Bravazzi on Facebook"
                  igLink="https://www.instagram.com/drinkbravazzi/"
                  igAlt="Follow Bravazzi on Instagram"
                />
              </div>
              <div className="vfy-brands__card">
                <CardBrand
                  name="itzSpritz"
                  logo={isLogo}
                  alt="Itz Spritz logo"
                  href="https://www.itzspritz.com"
                  cta="Visit ItzSpritz.com"
                  fbLink="https://www.facebook.com/ItzSpritz"
                  fbAlt="Follow Itz Spritz on Facebook"
                  igLink="https://www.instagram.com/itzspritz/"
                  igAlt="Follow Itz Spritz on Instagram"
                />
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </section>
  )
}
