import React from "react"
import "./Contact.scss"
import { Container } from "../layout/Container"
import { Field, Form, Formik, ErrorMessage } from "formik"
import Spinner from "../svg/spinner.svg"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import axios from "axios";
import qs from "qs"

gsap.registerPlugin(ScrollTrigger)

export const Contact = props => {
  
  
  return (
    <section id="someID" className="vfy-contact  vfy-section">
            <div className="diags"></div>

      <Container>
        <h2>Get In Touch With Us.</h2>
        <div className="skinnyForm">
          <Formik
            initialValues={{ email: "", nombre: "", messageText:"" }}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = "We need your email to respond."
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email =
                  "Please check your email address."
              } 
              if (!values.nombre) {
                errors.nombre = "Let us know what to call you."
              } 
              if (!values.messageText) {
                errors.messageText = "Please let us know your thoughts."
              } 
              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              axios({
                method: "POST",
                url: process.env.CONTACT_API_URL,
                data: qs.stringify(values),
                headers: {
                  "content-type": "application/x-www-form-urlencoded;charset=utf-8",
                },          
              })
                .then(response => {
                  setSubmitting(false);
                  resetForm();
                  console.log(response);
                  alert("Thank you for your interest in Vivify. We'll get back to you shortly.")
                })
                .catch(error => {
                  setSubmitting(false);
                  alert(error);
                  console.log(JSON.stringify(values, null, 2));
                  console.log(error);
                });
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2))
              //   alert(process.env.CONTACT_API_URL)
              //   setSubmitting(false)
              //   resetForm();
              // }, 400)
            }}
          >
            {({ isSubmitting }) => (
            <Form>
            
                <div className="formGroup">
                  <label htmlFor="nombre">Name</label>
                  <Field type="text" name="nombre"  />
                  <div className="messages">
                    <ErrorMessage name="nombre" component="div"  className="erroneous" />
                  </div>
                </div>
                <div className="formGroup">
                  <label htmlFor="email">Email Address</label>
                  <Field type="email" name="email" />
                  <div className="messages">
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="erroneous"
                    />
                  </div>
                </div>
                <div className="formGroup">
                  <label htmlFor="messageText">Message</label>
                  <Field name="messageText" component="textarea" />
                  <div className="messages">
                    <ErrorMessage
                      name="messageText"
                      component="div"
                      className="erroneous"
                    />
                  </div>
                </div>
                <div className="formGroup">
                  <button className="vfy-button" type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
                <div className={`loader${isSubmitting ? " loader--thinking":""}`}><Spinner/></div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  )
}
